<template>
	<div class="container">
		<ul class="list" v-if="list.length > 0">
			<li class="item" v-for="(items,index) in list" :key="index">
				<div class="guide">
					<div class="guide-1">导游</div>
					<div class="guide-2" style="float: left;">{{items.info.guide}}</div>
					<div class="guide-3">
						<a :href="`tel:`+items.info.guide_tel">联系导游</a>
					</div>
				</div>
				<div class="info">
					<div class="info-item">
						<span class="label">参观日期</span>
						<span>{{items.info.date}}</span>
					</div>
					<div class="info-item address">
						<span class="label">参观场所</span>
						<div class="desc">
							<p>{{items.info.venue_name}}</p>
							<div style="display: flex;">
								<img src="https://khome2.tuzuu.com/klfront/tourism/icon2.png" />
								<span>{{items.info.venue_addr}}</span>
							</div>
						</div>
					</div>
					<div class="info-item">
						<span class="label">预约信息</span>
						<div class="desc">
							<div v-for="(item,index) in items.list" :key="index">
								<p>
									<span style="color: #666666;">
										出行日期
										<font style="margin-left: 10px;color: #232323;">{{item.reserve_date}}</font>
									</span>
									<!-- 0预约中，1预约成功，2预约失败 -->
									<span class="success" v-if="item.state == 1">预约成功</span>
									<span class="wair" v-else-if="item.state == 0">预约中</span>
									<span class="error" v-else>预约失败</span>
									
								</p>
								<p>							
									<span>
										{{item.name}}
										<font style="margin-left: 10px;">{{item.mobile}}</font>
									</span>
								</p>
								<p class="address">{{item.id_no}}</p>
								<!-- <p class="address">{{item.reserve_date}}</p> -->
							</div>
							<div class="button" @click="href(items.info.group_id)">
								<van-icon name="plus" color="#21B48D"/>
								<span style="margin-left: 10px;">添加同行人</span>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<van-empty description="暂无数据" v-else />
	</div>
</template>

<script>
	export default {
		data(){
			return{
				list:[]
			}
		},
		created() {
			axios.get(
				'get_my_guide_group_info'
			).then(res => {
				this.list = res.data
				console.log(res)
			})
		},
		methods:{
			href(id){
				this.$router.push({
					path:'/subscribe',
					query:{group_id:id},
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.container{
		width: 100%;
		min-height: 100vh;
		box-sizing: border-box;
		padding: 10px 10px;
		background-color: #F8F8F8;
		.list{
			width: 100%;
			
			list-style: none;
			.item{
				padding: 10px 5px;
				box-sizing: border-box;
				background-color: white;
				border-radius: 8px;
				margin-bottom: 20px;
				.guide{
					overflow: hidden;
					padding: 0px 10px;
					padding-bottom: 8px;
					border-bottom: 1px solid #EAEAEA;
					font-size: 14px;
					.guide-1{
						float: left;
						font-size: 15px;
						margin-right: 40px;
						line-height: 30px;
						color: #232323;
					}
					.guide-2{
						color: #232323;
						line-height: 30px;
					}
					.guide-3{
						float: right;
						background: #F8F8F8;
						padding: 5px 10px;
						font-size: 14px;
						padding-left: 30px;
						background-image: url(https://khome2.tuzuu.com/klfront/tourism/icon1.png);
						background-repeat: no-repeat;
						background-size: 12px;
						background-position: 13% 50%;
						border-radius: 8px;
						color: #232323;
					}
				}
				.info{
					padding: 0px 8px;
					box-sizing: border-box;
					margin-top: 15px;
					font-size: 14px;
					.address{
						border-bottom: 1px solid #EAEAEA;
						padding-bottom: 10px;
					}
					.info-item{
						margin-bottom: 14px;
						color: #232323;
						display: flex;
						align-items: baseline;
						.label{
							color:#666666;
							white-space: nowrap;
							margin-right: 15px;
						}
						.desc{
							width: 100%;
							p{
								margin-bottom: 10px;
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 100%;
							}
							img{
								width: 15px;
								height: 18px;
								margin-right: 5px;
							}
							div:last-of-type p{
								border-bottom: none;
							}
							.success{
								color: #17AD84;
							}
							.error{
								color: #D83535;
							}
							.wair{
								color: #999999;
							}
							.button{
								width: fit-content;
								border: 1px dashed #21B48D;
								display: flex;
								align-items: center;
								padding: 8px 15px;
								font-size: 14px;
								border-radius: 4px;
							}
						}
					}
				}
			}
		}
	}
</style>